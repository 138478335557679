import { graphql, Link, useStaticQuery } from 'gatsby';
// import moment from 'moment';
import React from 'react';

const BlogTrendingPosts = () => {
  const { allStrapiBlog } = useStaticQuery(graphql`
    query {
      allStrapiBlog(limit: 3, sort: { fields: Date, order: DESC }) {
        edges {
          node {
            id
            Slug
            Date
            Title
          }
        }
      }
    }
  `);
  return (
    <div className="blog-trending-posts">
      <h2 className="title h3">Trending posts</h2>
      <div className="blog-list-wrap">
        <ul className="list-unstyled">
          {allStrapiBlog.edges.map(({ node }: any) => (
            <li>
              {/* <p>{moment(node.Date).format('MMMM DD, YYYY')}</p> */}
              <h6 className="title">
                <Link to={`/blog/${node.Slug}`}>{node.Title}</Link>
              </h6>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BlogTrendingPosts;
