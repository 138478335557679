import { graphql, useStaticQuery } from 'gatsby';
import React, { useEffect, useState } from 'react';
import BlogItem from '../components/blog/BlogItem';
import BlogSlider from '../components/blog/BlogSlider';
import BlogTrendingPosts from '../components/blog/BlogTrendingPosts';
import Footer from '../components/footer/Footer';
import HeaderCommon from '../components/header/HeaderCommon';
import Layout from '../components/layout';
import Seo from '../components/seo';
import BreadcrumbSection from '../elements/BreadcrumbSection';

const Blog = () => {
  const { allStrapiBlog } = useStaticQuery(graphql`
    query {
      allStrapiBlog(sort: { fields: Date, order: DESC }) {
        edges {
          node {
            id
            Slug
            Cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 500, height: 287)
                }
              }
            }
            Date
            Title
            Description
            ReadMinutes
          }
        }
      }
    }
  `);

  // Array of all Midea Mentions
  const allStrapiBlogs = allStrapiBlog.edges;

  // State for the list
  const [list, setList] = useState([...allStrapiBlogs.slice(0, 12)]);

  // State to trigger oad more
  const [loadMore, setLoadMore] = useState(false);

  // State of whether there is more to load
  const [hasMore, setHasMore] = useState(allStrapiBlogs.length > 12);

  // Load more button click
  const handleLoadMore = () => {
    setLoadMore(true);
  };

  // Handle loading more articles
  useEffect(() => {
    if (loadMore && hasMore) {
      const currentLength = list.length;
      const isMore = currentLength < allStrapiBlogs.length;
      const nextResults = isMore ? allStrapiBlogs.slice(currentLength, currentLength + 10) : [];
      setList([...list, ...nextResults]);
      setLoadMore(false);
    }
  }, [loadMore, hasMore]);

  // Check if there is more
  useEffect(() => {
    const isMore = list.length < allStrapiBlogs.length;
    setHasMore(isMore);
  }, [list]);

  return (
    <Layout>
      <Seo
        title="Blog | banco"
        description="Stay up to date with the latest articles and insights from banco. "
      />
      <main className="main-wrapper">
        <HeaderCommon />
        <BreadcrumbSection title="Blogs" />
        <div className="section">
          <div className="container">
            <div className="row row-40">
              <div className="col-lg-7">
                <BlogSlider />
              </div>
              <div className="col-lg-5">
                <BlogTrendingPosts />
              </div>
            </div>
          </div>
        </div>
        <div className="section section-blog-more-list">
          <div className="container">
            <div className="row row-35">
              {list.map((data) => (
                <div className="col-xl-4 col-md-6 pb--70" key={data.node.id}>
                  <BlogItem data={data.node} />
                </div>
              ))}
            </div>
            {hasMore && (
              <div className="row row-8 justify-content-md-center">
                <div className="col-lg-2 col-md-4 col-sm-6">
                  <button className="banco-btn btn-borderd btn-fluid" onClick={handleLoadMore}>
                    View more
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </main>
    </Layout>
  );
};

export default Blog;
