import { graphql, Link, useStaticQuery } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
// import moment from 'moment';
import React from 'react';
import Slider from 'react-slick';

const slideSettings = {
  infinite: true,
  arrows: false,
  swipeToSlide: false,
  draggable: false,
  dots: true,
  speed: 500,
  autoplaySpeed: 1500,
  autoplay: false,
  slidesToShow: 1,
  slidesToScroll: 1,
};

const BlogSlider = () => {
  const { allStrapiBlog } = useStaticQuery(graphql`
    query {
      allStrapiBlog(limit: 3, sort: { fields: Date, order: DESC }) {
        edges {
          node {
            id
            Slug
            Cover {
              localFile {
                childImageSharp {
                  gatsbyImageData(width: 650, height: 375)
                }
              }
            }
            Date
            Description
            Title
            ReadMinutes
          }
        }
      }
    }
  `);
  return (
    <div className="blog-slider">
      <h2 className="title h3">Featured</h2>
      <Slider {...slideSettings} className="slick-dot-nav">
        {allStrapiBlog.edges.map(({ node }: any) => (
          <div className="single-slide" key={node.id}>
            <div className="thumbnail">
              <Link to={`/blog/${node.Slug}`}>
                <GatsbyImage
                  image={node.Cover?.localFile?.childImageSharp?.gatsbyImageData}
                  alt={node.Title}
                />
              </Link>
            </div>
            <div className="content">
              <h3 className="title">
                <Link to={`/blog/${node.Slug}`} title={node.Title}>
                  {node.Title}
                </Link>
              </h3>
              <p>{node.Description ?? ''}</p>
              <div className="info">
                <ul className="media-meta list-unstyled">
                  {/* <li>{moment(node.Date).format('MMMM DD, YYYY')}</li> */}
                  <li>{node.ReadMinutes} min read</li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default BlogSlider;
